/* Cards Container */
.cards-container {
    /*margin-top: 30px;*/
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

/* Card */
.card {
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    text-align: center;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Card Image */
.card-image {
    margin: 0 auto 20px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ddd;
    width: 80px;
    height: 80px;
    align-content: center;
    justify-content: center;
    background-color: #3c763d;
    color: whitesmoke;
}

.card-image img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

/* Card Header */
.card-header h3 {
    margin: 0;
    font-size: 18px;
    color: rgb(7, 153, 146);
}

.card-header p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #555;
}

.card-footer {
    margin-top: 50px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}
.card-footer button {
    border: 1px solid rgba(7, 153, 146, 1);
    border-radius: 50px;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    margin-top: 100px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
    max-height: 80vh;
    overflow-y: auto;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Close Button */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
}

.modal-close:hover {
    color: rgba(7, 153, 146, 1);
}

/* Modal Title */
.modal-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: rgba(7, 153, 146, 1);
}

/* Modal Content Section */
.content-modal .content-section {
    margin-bottom: 20px;
}

.content-modal .content-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.content-modal .content-section ul {
    padding-left: 20px;
    list-style-type: disc;
}

.content-modal .content-section p {
    font-size: 14px;
    color: #444;
    line-height: 1.6;
}